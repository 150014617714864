// This file configures the initialization of Sentry on the server.
// The config you add here will be used whenever the server handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN =
    process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const SENTRY_ENV = process.env.SENTRY_ENVIRONMENT

Sentry.init({
  dsn: SENTRY_DSN || 'https://7fb65092a60d4541b30861d6f9fa6d4f@o1019182.ingest.sentry.io/6042239',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.01,
  enabled: SENTRY_ENV == 'production'
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})