import {CacheProvider, EmotionCache, jsx} from '@emotion/react'
import {AuthContext} from '@ezchops/base-components/dist/components/layout/AuthContext'
import {LightThemeProvider} from '@ezchops/base-components/dist/mui'
import CssBaseline from '@mui/material/CssBaseline'
import {EzChopsPage} from 'components/app/EzChopsPage'
import {SentryErrorHandlerBoundary, swrConfig, useGoogleTagManager} from 'lib/app'
import createEmotionCache from 'lib/mui/createEmotionCache'
import {Session,} from 'next-auth'
import {SessionProvider, signIn, signOut, useSession} from 'next-auth/react'

import {AppProps as NextAppProps, NextWebVitalsMetric} from 'next/app'
import Head from 'next/head'
import * as React from 'react'
import {ReactNode} from 'react'
import TagManager from 'react-gtm-module'
import {SWRConfig} from 'swr'
import IntrinsicAttributes = jsx.JSX.IntrinsicAttributes

const clientSideEmotionCache = createEmotionCache()

interface AppProps<P = {}> extends NextAppProps<P> {
    emotionCache?: EmotionCache;
    Component: EzChopsPage<P>
}


export function reportWebVitals(metric: NextWebVitalsMetric) {
    const {id, name, label, value} = metric

    TagManager.dataLayer({
        dataLayer: {
            event: name,
            event_category:
                label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
            value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
            event_label: id, // id unique to current page load
            non_interaction: true, // avoids affecting bounce rate.
        },
        dataLayerName: 'MetricLayer'
    })

}


export default function EzChopsApp<P extends IntrinsicAttributes & { session?: Session | null }>(
    {Component, emotionCache = clientSideEmotionCache, pageProps}: AppProps<P>) {

    useGoogleTagManager()
    const getLayout = Component.getLayout || ((page: ReactNode) => page)

    const {session} = pageProps

    return <React.StrictMode><SWRConfig value={swrConfig}>
        <CacheProvider value={emotionCache}>
            <Head>
                <title>dobolj.hu</title>
                <meta name="viewport" content="initial-scale=1, width=device-width"/>
            </Head>
            <SessionProvider session={session} refetchInterval={5 * 60}>
                <AuthContextProvider>
                    <LightThemeProvider>
                        <SentryErrorHandlerBoundary>
                            <CssBaseline/>
                            {getLayout(<Component {...pageProps} />)}
                        </SentryErrorHandlerBoundary>
                    </LightThemeProvider>
                </AuthContextProvider>
            </SessionProvider>
        </CacheProvider>
    </SWRConfig></React.StrictMode>
}

function AuthContextProvider({children}: React.PropsWithChildren<{}>) {
    const {data: session} = useSession()
    return <AuthContext.Provider value={{user: session?.user, signOut, signIn}}>
        {children}
    </AuthContext.Provider>
}