export const swrConfig = {
    fetcher,
    onError: (error: any, key: any) => {
        if (error.status !== 403 && error.status !== 404) {

        }
    }
}

class APIError extends Error {
    constructor(message: string,
        readonly url: string,
        readonly status: number, readonly statusText: string,
        readonly info: any) {
        super(message)
    }

    static async fromResponse(url: string, res: Response) {
        const info = await res.json()
        return new APIError(`Unable to fetch ${url}`, url, res.status, res.statusText, info)
    }
}

async function fetcher(url: string) {
    const res = await fetch(url)

    if (!res.ok) {
        throw await APIError.fromResponse(url, res)
    }

    return res.json()
}