import {useEffect} from 'react'
import TagManager from 'react-gtm-module'
import {getSession} from 'next-auth/react'

const gtmId = process.env.NEXT_PUBLIC_GTM_TRACKING_ID

export function useGoogleTagManager() {
    useEffect(() => {
        getSession().then(
            session => {
                if (process.env.GTM_DISABLED !== 'true' && gtmId) {
                    const gtmArgs = {
                        gtmId,
                        dataLayer: {
                            userId: session?.user?.id
                        }
                    }
                    TagManager.initialize(gtmArgs)
                }
            }
        )
    }, [])
}
  