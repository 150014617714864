import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import * as Sentry from '@sentry/nextjs'
import * as React from 'react'
import {Component, ErrorInfo, ReactNode} from 'react'

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

export class SentryErrorHandlerBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    }

    public static getDerivedStateFromError(_: Error): State {
        return {hasError: true}
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        Sentry.captureException(error, (scope) =>
            scope.setExtra('componentStack', errorInfo.componentStack)
        )
    }

    public render() {
        return this.props.children
    }
}


function ErrorDetails() {
    return <Grid container spacing={0} alignItems="center" justifyContent="center">
        <Grid>
            <Box>
                <Typography component="p" variant="h1">
                    An unexpected error has occurred.
                </Typography>
            </Box>
        </Grid>
    </Grid>
}
